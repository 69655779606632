import React, { useState } from 'react';
import { register } from '../actions/auth';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Typography, Paper, Grid, Divider } from '@mui/material';
import { useTheme } from '@emotion/react';
import RegisterForm from '../components/register/RegisterForm';
import GoogleSignUpButton from '../components/register/GoogleSignUpButton';
import AccountCreatedMessage from '../components/register/AccountCreatedMessage';
import LoginButton from '../components/general/LoginButton';

const Register = ({ register, isAuthenticated }) => {
    const theme = useTheme();
    const [accountCreated, setAccountCreated] = useState(false);
    const [backendErrors, setBackendErrors] = useState({});

    const handleFormSubmit = async (e, formData) => {
        e.preventDefault();
        const { email, password, re_password } = formData
        try {
            await register(email, password, re_password);
            setAccountCreated(true);
        } catch (error) {
            // Assuming the backend returns error messages like { email: 'Email already taken' }
            setBackendErrors(error.response?.data || { general: 'Registration failed. Please try again.' });
        }
      
    };

    if (isAuthenticated) {
        return <Navigate to="/investments" />;
    }

    if (accountCreated) {
        return <AccountCreatedMessage />;
    }

    return (
        <div style={{ display: 'flex', padding: 20, backgroundColor: theme.palette.primary.main, color: 'white', justifyContent: 'center' }}>
            <Grid item xs={12} lg={10} display='flex' spacing={2} padding='40px'>
            <Grid component={Paper} style={{ padding: '60px' }}>
                    <Typography variant="h4" gutterBottom>
                        Register to start investing
                    </Typography>

            
                    <RegisterForm onSubmit={handleFormSubmit} backendErrors={backendErrors}/>

                    <Divider>or</Divider>

                    <GoogleSignUpButton />

                    <Grid display={'flex'} justifyContent={'center'} flexDirection={'column'} alignContent={'center'}>

                    <Typography variant="body1" style={{ marginTop: '30px', alignContent:'center' }}>
                        Already have an account?
                    </Typography>

                    <LoginButton />

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, { register })(Register);
