import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const ChoiceStep = ({ step, formData, setFormData }) => {
    const theme = useTheme();

    const handleChoiceChange = (questionId, choiceId) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: choiceId,
        }));
    };
    console.log(step)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>{step.question.question}</Typography>
            {step.question.choices.map((choice) => (
                <Card
                    key={choice.id}
                    onClick={() => handleChoiceChange(step.question.id, choice.id)}
                    sx={{
                        backgroundColor: formData[step.question.id] === choice.id ? theme.palette.secondary.light : 'white',
                        width: '50%',
                    }}
                >
                    <CardActionArea>
                        <CardContent>
                            <Typography variant="body1">{choice.name}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </Box>
    );
};

export default ChoiceStep;
