import React, { useState, useEffect, useCallback } from 'react';
import api from '../../axiosInstance';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Alert } from '@mui/material';
import Cookies from 'js-cookie';

const stripePromise = loadStripe('pk_test_51KlGsICFu7uROflVwYpmFGj8Xr9KVwJr7c6ZJt89PinNyUMHyM4OHjgZACCw3tuCQz5wp253LR9C7ypw5MTHSRNI00GR6kClfJ');
// TODO: redo this file so its smaller 

const CheckoutForm = ({ formData, dealId, orderId }) => {

  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);
  const [hasPaid, setHasPaid] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
      }
    }

    const fetchCheckoutSession = async () => {

      try {

          const checkoutSession = await api.post('/api/orders/create_checkout_session/', {

              price: "price_1Ps3UQCFu7uROflV4KmutHzj",
              quantity: formData.quantity,

          }, config);

        if (checkoutSession.status !== 200) {
          throw new Error('Failed to create checkout session');
        }

        
        const { clientSecret, id: sessionId } = checkoutSession.data;

        setClientSecret(clientSecret);
        setCheckoutSessionId(sessionId);

      } catch (error) {
        setError('Failed to create a checkout session');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCheckoutSession();

  }, [formData]);

  const checkSessionStatus = useCallback(async () => {

    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
    };

    try {
      const response = await api.get(`/api/orders/checkout_session_status/${checkoutSessionId}/`, {}, config);
      
      console.log(response.data.status)

      if (response.data.status === 'paid') {

        setHasPaid(true);

        const orderResponse = await api.put(`/api/orders/update_status/${orderId}/`, {'status':'1'}, config);

        if (orderResponse.status === 201) {
        } else {
          throw new Error('Failed to update the order');
        }

      } else {
       
        setTimeout(checkSessionStatus, 5000); 

      }
    } catch (error) {
      console.error('Failed to check session status:', error);
      setError('Failed to check the session status.');
    }
  }, [checkoutSessionId, orderId]);


  useEffect(() => {
    if (!hasPaid && checkoutSessionId) {
      checkSessionStatus();
    }
  }, [checkoutSessionId, checkSessionStatus, hasPaid]); 

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div id="checkout">
      {!hasPaid && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
      {/* Display submission confirmation if isSubmitted is true */}
      {hasPaid && (
        <Box>

          <Alert severity="success">
            Your Order Has Been Reserved. You will receive all the required documentation by email.
          </Alert>

          <Button variant="contained" color="primary" onClick={() => navigate('/portfolio')}>
            Go to Your Portfolio
          </Button>

          <Button variant="contained" color="primary" onClick={() => navigate('/investments')}>
            Invest More
          </Button>
        </Box>
      )}
    </div>
  );
};

export default CheckoutForm;
