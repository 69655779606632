import React from 'react';
import { Box, Alert, FormControlLabel, Checkbox } from '@mui/material';
import Rulebook from '../../pages/RuleBook';

const AcceptTerms = ({ formData, handleChange }) => {
  return (
    <Box>
      {/* Info Alert */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Alert severity="info">
          The fund accepts investment reservations based on the order in which a commitment is made, in accordance with our allocation policy.
          Once the fund reaches capacity, reservations will be placed on the waitlist.
        </Alert>
      </Box>

      {/* Scrollable Rulebook */}
      <Box sx={{ height: '50vh', overflowY: 'auto', padding: '20px' }}>
        <Rulebook title="TermSheet" sx={{ color: 'white' }} />
      </Box>

      {/* Warning Alert */}
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <Alert severity="warning">
          Please scroll to read the agreement
        </Alert>
      </Box>

      {/* Checkbox for agreement */}
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.term_sheet_acceptance}
              onChange={handleChange}
              name="term_sheet_acceptance"
            />
          }
          label="I confirm I have read and agree to the terms"
        />
      </Box>
    </Box>
  );
};

export default AcceptTerms;
