import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const DateStep = ({ step, formData, setFormData }) => {
    const handleDateChange = (questionId, date) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: date,
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>{step.question.question}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Select Date"
                    value={formData[step.question.id] || null}
                    onChange={(newValue) => handleDateChange(step.question.id, newValue)}
                    renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default DateStep;
