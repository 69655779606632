import { Button } from '@mui/material';
import api from '../../axiosInstance';
import Cookies from 'js-cookie';

const NavButtons = ({ tabValue, setTabValue, deal, formData, totalTabs, setIsSubmitted }) => {
  const handleNext = () => {
    const nextTab = parseInt(tabValue) + 1;
    setTabValue(nextTab.toString());
  };

  const handleBack = () => {
    const prevTab = parseInt(tabValue) - 1;
    setTabValue(prevTab.toString());
  };

  const handleWaitingListSignUp = async () => {
    const orderData = {
      deal: deal.id,
      reservation_amount: formData.reservation_amount,
      equivalent_number_of_shares: formData.equivalent_number_of_shares,
      final_reservation_amount: formData.final_reservation_amount,
      status: 2
    };

    try {
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      };

      const orderResponse = await api.post('/api/orders/place_order/', orderData, config);

      if (orderResponse.status === 201) {
        setIsSubmitted(true); // Handle successful submission
      } else {
        throw new Error('Failed to place order in the backend');
      }
    } catch (error) {
      console.error('Error placing order:', error.message);
    }
  };

const handleOrderSubmit = async () => {
    const orderData = {
      deal: deal.id,
      reservation_amount: formData.reservation_amount,
      equivalent_number_of_shares: formData.equivalent_number_of_shares,
      final_reservation_amount: formData.final_reservation_amount,
      status: 1
    };

    try {
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      };

      const orderResponse = await api.post('/api/orders/place_order/', orderData, config);

      if (orderResponse.status === 201) {
        setIsSubmitted(true); // Handle successful submission
      } else {
        throw new Error('Failed to place order in the backend');
      }
    } catch (error) {
      console.error('Error placing order:', error.message);
    }
  };





  if (tabValue === "1") {
    return (
      <Button variant="contained" color="primary" onClick={handleNext}>
        Next
      </Button>
    );
  } else if (tabValue === totalTabs.toString() & totalTabs===3) {
    return (
      <>
        <Button variant="contained" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="secondary" onClick={handleWaitingListSignUp}>
          Join Waiting List
        </Button>
      </>
    );
  } else if (tabValue === totalTabs.toString() & totalTabs===4) {
    return (
      <>
        <Button variant="contained" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="secondary" onClick={handleOrderSubmit}>
          Confirm and Pay
        </Button>
      </>
    );
  }  
  else {
    return (
      <>
        <Button variant="contained" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </>
    );
  }
};

export default NavButtons;
