import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Grid, Link } from '@mui/material';
import { Facebook, X, LinkedIn, Instagram, LocationOn, Phone, Email } from '@mui/icons-material';
import images from '../../images';
import { SecondaryIcon } from '../ColouredIcons';

const Footer = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.background.dark,
                color: theme.palette.text.light,
                pt: 5,
                pb: 3,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Grid container justifyContent="center" spacing={2} sx={{ pb: 5 }}>
                {/* Logo & Social Media Column */}
                <Grid item xs={12} md={3}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <img
                            src={images.logo}
                            alt="Company Logo"
                            style={{ maxHeight: '150px', height: 'auto', marginBottom: theme.spacing(2) }}
                        />
                        <Box display="flex" justifyContent="center" gap={2}>
                            <Link href="https://www.facebook.com/" target="_blank">
                                <SecondaryIcon variant="secondary" IconComponent={Facebook} />
                            </Link>
                            <Link href="https://www.twitter.com/" target="_blank">
                                <SecondaryIcon variant="secondary" IconComponent={X} />
                            </Link>
                            <Link href="https://www.linkedin.com/" target="_blank">
                                <SecondaryIcon variant="secondary" IconComponent={LinkedIn} />
                            </Link>
                            <Link href="https://www.instagram.com/" target="_blank">
                                <SecondaryIcon variant="secondary" IconComponent={Instagram} />
                            </Link>
                        </Box>
                    </Box>
                </Grid>

               {/* Company Column */}
<Grid item xs={12} md={2}>
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Company</Typography>
    <Link href="/about" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Who we are</Link>
    <Link href="/careers" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Career</Link>
    <Link href="/press" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Press</Link>
    <Link href="/help" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Help</Link>
</Grid>

{/* Policy Column */}
<Grid item xs={12} md={2}>
    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Policy & Disclosures</Typography>
    <Link href={`/documents/rulebooks/${encodeURIComponent('Disclosures')}`} color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Disclosures</Link>
    <Link href={`/documents/rulebooks/${encodeURIComponent('Business Continuity')}`} color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Business Continuity</Link>
    <Link href={`/documents/rulebooks/${encodeURIComponent('Privacy Policy')}`} color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Privacy Policy</Link>
    <Link href={`/documents/rulebooks/${encodeURIComponent('Allocation Policy')}`} color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Allocation Policy</Link>
</Grid>


                {/* Contact Column */}
                {/* Get in Touch Column */}
                <Grid item xs={12} md={3}>
                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, textAlign:'center' }}>Get in Touch with Us</Typography>

                        {/* Address Section */}
                        <Box display="flex" flexDirection="row" alignItems="center" gap={1} mb={2}>
                            <SecondaryIcon variant="secondary" IconComponent={LocationOn} />
                            <Typography>
                                71-75 Shelton Street<br />
                                Covent Garden<br />
                                London WC2H 9JQ<br />
                                United Kingdom
                            </Typography>
                        </Box>

                        {/* Phone Section */}
                        <Box display="flex" flexDirection="row" alignItems="center" gap={1} mb={2}>
                            <SecondaryIcon variant="secondary" IconComponent={Phone} />
                            <Typography>394-091-3312</Typography>
                        </Box>

                        {/* Email Section */}
                        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                            <SecondaryIcon variant="secondary" IconComponent={Email} />
                            <Link href="mailto:support@EquitiBridge.com" color="inherit" underline="none">support@EquitiBridge.com</Link>
                        </Box>
                    </Box>
                </Grid>

            </Grid>

            {/* Legal Notice */}
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} md={10}>
                    <Typography variant="body2" sx={{ textAlign: 'justify', mb: 2 }}>
                        Investment opportunities posted on this website are "private placements" of securities that are not publicly traded,
                        are subject to holding period requirements, and are intended for investors who do not need a liquid investment.
                        Investing in private companies may be considered highly speculative and involves a high degree of risk,
                        including the risk of substantial loss of investment. Investors must be able to afford the loss of their entire investment.
                        See our Risk Factors for a more detailed explanation of the risks involved by investing through EquitiBridge’s platform.
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                        EquitiBridge Ltd is an appointed representative of ODIN Capital Management Ltd registered with the FCA under number XXXXX. EquitiBridge.com is a website operated by EquitiBridge Ltd ("EquitiBridge").By accessing this site, you agree to be bound by our <Link href={`/documents/rulebooks/${encodeURIComponent('Terms & Conditions')}`} color="inherit">Terms & Conditions</Link>.
                        EquitiBridge logo are trademarks of EquitiBridge Ltd. Other trademarks are property of their respective owners.
                    </Typography>
                </Grid>
            </Grid>

            {/* Copyright Notice */}
            <Typography variant="body2" sx={{ mt: 4, fontSize: '12px' }}>
                © 2024 EquitiBridge Ltd. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
