import React, { useState } from "react";
import ScreeningForm from "../components/ScreeningForm";
import { connect } from 'react-redux';
import { Grid, Button, Paper, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const forms = [['Verification', 'register_verification'], ['Agreements', 'register_agreements'], ['Profile', 'register_profile'],['Survey', 'register_survey'],['Goals', 'register_goals']]

const Screen = ({ register, isAuthenticated }) => {
  const [formsCompleted, setFormsCompleted] = useState([1, 0, 0, 0, 0]); 
  const theme=useTheme();
  const navigate = useNavigate();

  const handleFormComplete = (formIndex) => {
    const updatedFormsCompleted = [...formsCompleted];
    updatedFormsCompleted[formIndex] = 2;
    if (formIndex < 4){
    updatedFormsCompleted[formIndex+1]=1;
    }
    setFormsCompleted(updatedFormsCompleted);
  };

  const navigateToInvestments = () => {
    
    navigate('/investments');
    };

  return (
    <div>
      <Grid container spacing={2} justifyContent='center' padding='20px'>
        <Grid item xs={12} lg={10} padding='20px'>
          <Paper sx={{ padding:'40px' }}>
            {formsCompleted.map((status, index) => (
              <Box key={index} sx={{ borderRadius:'10px', bgcolor: status === 0 ? 'grey.300' : status === 1 ? 'white' : theme.palette.secondary.light, padding:'30px', m: 2 }}>
                {status === 0 && `${forms[index][0]} Section Awaiting Completion`}
                {status === 1 && 
                <div>
                <ScreeningForm formName={forms[index][1]} onFormComplete={() => handleFormComplete(index)} />
                </div>
                }
                {status === 2 && `${forms[index][0]} Section Completed`}
              </Box>
            ))}
            {formsCompleted.every(status => status === 2) && (
              <Button variant='contained' color='primary' onClick={navigateToInvestments}>Complete and proceed to investments</Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.isAuthenticated
});

export default connect(mapStateToProps)(Screen);
