import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, LinearProgress } from '@mui/material';
import { loadFormData, filterStepsByCondition } from './screening/formHelpers';
import { names } from './screening/formConstants';
import StepRenderer from './screening/StepRenderer';

const ScreeningForm = ({ formName, onFormComplete }) => {
    const [formSteps, setFormSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [isAccepted, setIsAccepted] = useState({});

    useEffect(() => {
        const fetchFormData = async () => {
            const response = await loadFormData(formName);
            const filteredSteps = filterStepsByCondition(response.steps, formData);
            setFormSteps(filteredSteps);
        };
        fetchFormData();
    }, [formName, formData]);

    const handleNext = () => setCurrentStep(prevStep => prevStep + 1);

    const handleSubmit = () => {
        console.log('Submitting data', formData);
        if (onFormComplete) onFormComplete();
    };

    const isLastStep = currentStep === formSteps.length - 1;
    
    return (
        <Box>
            <LinearProgress variant="determinate" color="secondary" value={(currentStep / formSteps.length) * 100} />
            <Typography padding="20px" variant="h4" textAlign="center">{names[formName]}</Typography>
            <Box padding="20px">
                {formSteps.length > 0 && (
                    <StepRenderer
                        step={formSteps[currentStep]}
                        formData={formData}
                        setFormData={setFormData}
                        isAccepted={isAccepted}
                        setIsAccepted={setIsAccepted}
                    />
                )}
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                {currentStep < formSteps.length - 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={formSteps[currentStep]?.type === 'accept' && !isAccepted[formSteps[currentStep].id]}
                    >
                        Next
                    </Button>
                )}
                {isLastStep && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ScreeningForm;
