import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, Button, Typography } from '@mui/material';
import CSRFToken from '../general/CSRFToken';
import { Link } from 'react-router-dom';

const RegisterForm = ({ onSubmit, backendErrors }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        re_password: ''
    });

    const [errors, setErrors] = useState({});  // State for validation errors

    const { email, password, re_password } = formData;

    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    // Email validation function
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    // Frontend Form validation
    const validateForm = () => {
        let formErrors = {};

        if (!validateEmail(email)) {
            formErrors.email = 'Please enter a valid email address.';
        }

        if (password.length < 6) {
            formErrors.password = 'Password must be at least 6 characters long.';
        }

        if (password !== re_password) {
            formErrors.re_password = 'Passwords do not match.';
        }

        return formErrors;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);  // Set frontend validation errors
        } else {
            setErrors({});  // Clear frontend errors if no issues
            onSubmit(e, formData);  // Submit form, backend errors will be handled later
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CSRFToken />

            {/* Email Field */}
            <FormControl variant="outlined" fullWidth margin="normal" required error={!!errors.email || !!backendErrors?.email}>
                <InputLabel htmlFor="email">Email address</InputLabel>
                <OutlinedInput
                    id="email"
                    name="email"
                    label="Email"
                    value={email}
                    onChange={handleChange}
                />
                {errors.email && <Typography color="error">{errors.email}</Typography>}
                {backendErrors?.email && <Typography color="error">{backendErrors.email}</Typography>}
            </FormControl>

            {/* Password Field */}
            <FormControl variant="outlined" fullWidth margin="normal" required error={!!errors.password}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={handleChange}
                />
                {errors.password && <Typography color="error">{errors.password}</Typography>}
            </FormControl>

            {/* Confirm Password Field */}
            <FormControl variant="outlined" fullWidth margin="normal" required error={!!errors.re_password}>
                <InputLabel htmlFor="re_password">Confirm Password</InputLabel>
                <OutlinedInput
                    id="re_password"
                    name="re_password"
                    label="Confirm Password"
                    type="password"
                    value={re_password}
                    onChange={handleChange}
                />
                {errors.re_password && <Typography color="error">{errors.re_password}</Typography>}
            </FormControl>


            <Typography variant='body1' padding='20px'>
                By clicking Sign Up, you agree to EquitiBridge's{' '}
                <Link to="/documents/rulebooks/UserAgreement">User Agreement</Link>,{' '}
                <Link to="/documents/rulebooks/PrivacyPolicy">Privacy Policy</Link> &{' '}
                <Link to="/documents/rulebooks/CookiePolicy">Cookie Policy</Link>.
            </Typography>
            {/* Submit Button */}
            <Button type="submit" variant="contained" color="secondary">
                Sign Up
            </Button>

            {/* General backend error message */}
            {backendErrors?.general && <Typography color="error" style={{ marginTop: '10px' }}>{backendErrors.general}</Typography>}
        </form>
    );
};

export default RegisterForm;

