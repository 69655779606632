import React from 'react';
import {
  TextField, Grid, Box, Paper, Typography
} from '@mui/material';

const ReservationDetails = ({ formData, handleChange, deal, company }) => {
  
  const renderTextField = (label, name, value, disabled = false) => (
    <Grid item xs={12} lg={12}>
      <TextField
        required
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        fullWidth
      />
    </Grid>
  );

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={2}>
        {/* Company Information */}
        {company && (
          <Grid item xs={12} lg={3}>
            <Paper 
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                padding: '50px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '30vh'
              }}
            >
              <img alt="Company Logo" src={company.logo} width="50%" />
              <Typography variant="h4" marginTop="30px">
                {company.name}
              </Typography>
            </Paper>
          </Grid>
        )}

        {/* Reservation Form */}
        <Grid item xs={12} lg={9}>
          <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
            {renderTextField('Number of Shares', 'equivalent_number_of_shares', formData.equivalent_number_of_shares)}
            {renderTextField('Share Price (excl. Sales Fee)', 'share_price', formData.share_price, true)}
            {renderTextField('Reserved Value of Stocks', 'final_reservation_amount', formData.final_reservation_amount, true)}
            {renderTextField('Final amount', 'reservation_amount', formData.reservation_amount, true)}
          </Grid>
        </Grid>

        {/* Deal Information */}
        {deal && (
          <Grid item xs={12} lg={12}>
            <Typography variant="body1" sx={{ fontSize: '0.8em' }}>
              EquitiBridge does not guarantee the availability of Deals in the future for this fund or any fund.
              To be eligible to sell this position in a Deal, your Reservation Amount must be {deal.minimum_investment_size} GBP or greater.
              You will have the opportunity to review all information prior to signing your subscription agreement and completing the investment process.
              The Reservation Amount that you have entered falls below our investment minimum.
              As such, we have rounded your investment up to the nearest number of shares that satisfies our investment minimum.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ReservationDetails;
