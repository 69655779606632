import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import api from '../../axiosInstance';
import Loading from './Loading';
import { useTheme } from '@mui/material/styles';

const MarketplaceBanner = () => {
    const [daysUntilNewListing, setDaysUntilNewListing] = useState(null);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();


    useEffect(() => {
        const fetchNewListingData = async () => {
            try {
                const response = await api.get('/api/new-listing'); // Adjust the endpoint as necessary
                
                // If no valid date is returned, throw an error
                if (!response.data.next_listing_date) {
                    throw new Error('No listing date available');
                }

                const newListingDate = new Date(response.data.next_listing_date);
                const currentDate = new Date();

                // Calculate days remaining only if newListingDate is valid
                if (!isNaN(newListingDate.getTime())) {
                    const daysRemaining = Math.ceil((newListingDate - currentDate) / (1000 * 60 * 60 * 24));
                    setDaysUntilNewListing(daysRemaining);
                } else {
                    throw new Error('Invalid date format');
                }

            } catch (error) {
                console.error('Error fetching new listing date:', error);
                // Set to 0 or a fallback value if there's an error
                setDaysUntilNewListing(0);
            } finally {
                setLoading(false);
            }
        };

        fetchNewListingData();
    }, []);

    if (loading) {
        return <Loading/>
    }

    return (
        <Grid
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{
            backgroundColor: theme.palette.primary.main, // Use theme's primary color as background
            color: theme.palette.text.light, // Ensure text is readable on primary background
            padding: '40px',
            textAlign: 'center',
  
        }}
    >

            <Typography variant="h3" gutterBottom>
                Private Company Marketplace
            </Typography>

            <Typography variant="body1" gutterBottom>
                The biggest marketplace for buyers and sellers of Europe's hottest startups
            </Typography>

            <Button
                variant="bright"
                color='secondary'
                component={Link}
            >
                {daysUntilNewListing > 0 ? `${daysUntilNewListing} Days until a new listing` : 'New Listings Upcoming'}
            </Button>

            <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                <Button variant="contained" color="secondary" component={Link} to="/investments">
                    How it works
                </Button>
                <Button variant="contained" color="secondary" component={Link} to="/investments">
                    Join our waitlist
                </Button>
            </Box>
        </Grid>
    );
};

export default MarketplaceBanner;
