import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, OutlinedInput, Grid, Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { update_investor_profile } from '../../actions/profile'; // Ensure this action creator exists
import CSRFToken from '../../components/general/CSRFToken';
// TODO: redo this file so its smaller 

const InvestingProfile = ({
    update_investor_profile,
    investing_anticipation_global,
    target_investment_per_investment_global,
    interested_investment_solution_global,
    other_assets_global,
    max_investment_horizon_global,
    risk_acceptance_global,
    investment_experience_global,
    liquid_worth_global,
    risk_factors_global,
    qualified_purchaser_global
}) => {

    const [formData, setFormData] = useState({
        investing_anticipation: '',
        target_investment_per_investment: '',
        interested_investment_solution: '',
        other_assets: '',
        max_investment_horizon: '',
        risk_acceptance: '',
        investment_experience: '',
        liquid_worth: '',
        risk_factors: '',
        qualified_purchaser: ''
    });

    const {
        investing_anticipation,
        target_investment_per_investment,
        interested_investment_solution,
        other_assets,
        max_investment_horizon,
        risk_acceptance,
        investment_experience,
        liquid_worth,
        risk_factors,
        qualified_purchaser
    } = formData;

    useEffect(() => {
        setFormData({
            investing_anticipation: investing_anticipation_global,
            target_investment_per_investment: target_investment_per_investment_global,
            interested_investment_solution: interested_investment_solution_global,
            other_assets: other_assets_global,
            max_investment_horizon: max_investment_horizon_global,
            risk_acceptance: risk_acceptance_global,
            investment_experience: investment_experience_global,
            liquid_worth: liquid_worth_global,
            risk_factors: risk_factors_global,
            qualified_purchaser: qualified_purchaser_global
        });
    }, [
        investing_anticipation_global,
        target_investment_per_investment_global,
        interested_investment_solution_global,
        other_assets_global,
        max_investment_horizon_global,
        risk_acceptance_global,
        investment_experience_global,
        liquid_worth_global,
        risk_factors_global,
        qualified_purchaser_global
    ]);

    const onChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const onSubmit = e => {
        e.preventDefault();
        update_investor_profile(
            investing_anticipation,
            target_investment_per_investment,
            interested_investment_solution,
            other_assets,
            max_investment_horizon,
            risk_acceptance,
            investment_experience,
            liquid_worth,
            risk_factors,
            qualified_purchaser
        );
    };

    return (
        <form onSubmit={e => onSubmit(e)}>
            <CSRFToken />
            <Typography variant="h6" gutterBottom>
                Investing Profile
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='investing_anticipation'>Investing Anticipation</InputLabel>
                        <OutlinedInput
                            id='investing_anticipation'
                            name='investing_anticipation'
                            label='Investing Anticipation'
                            value={investing_anticipation}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='target_investment_per_investment'>Target Investment Per Investment</InputLabel>
                        <OutlinedInput
                            id='target_investment_per_investment'
                            name='target_investment_per_investment'
                            label='Target Investment Per Investment'
                            value={target_investment_per_investment}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='interested_investment_solution'>Interested Investment Solution</InputLabel>
                        <OutlinedInput
                            id='interested_investment_solution'
                            name='interested_investment_solution'
                            label='Interested Investment Solution'
                            value={interested_investment_solution}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='other_assets'>Other Assets</InputLabel>
                        <OutlinedInput
                            id='other_assets'
                            name='other_assets'
                            label='Other Assets'
                            value={other_assets}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='max_investment_horizon'>Max Investment Horizon</InputLabel>
                        <OutlinedInput
                            id='max_investment_horizon'
                            name='max_investment_horizon'
                            label='Max Investment Horizon'
                            value={max_investment_horizon}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='risk_acceptance'>Risk Acceptance</InputLabel>
                        <OutlinedInput
                            id='risk_acceptance'
                            name='risk_acceptance'
                            label='Risk Acceptance'
                            value={risk_acceptance}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='investment_experience'>Investment Experience</InputLabel>
                        <OutlinedInput
                            id='investment_experience'
                            name='investment_experience'
                            label='Investment Experience'
                            value={investment_experience}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='liquid_worth'>Liquid Worth</InputLabel>
                        <OutlinedInput
                            id='liquid_worth'
                            name='liquid_worth'
                            label='Liquid Worth'
                            value={liquid_worth}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='risk_factors'>Risk Factors</InputLabel>
                        <OutlinedInput
                            id='risk_factors'
                            name='risk_factors'
                            label='Risk Factors'
                            value={risk_factors}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='qualified_purchaser'>Qualified Purchaser</InputLabel>
                        <OutlinedInput
                            id='qualified_purchaser'
                            name='qualified_purchaser'
                            label='Qualified Purchaser'
                            value={qualified_purchaser}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" color='secondary' type="submit" sx={{ mt: 3 }}>
                Update Investing Profile
            </Button>
        </form>
    );
};

const mapStateToProps = state => ({
    investing_anticipation_global: state.profile.investing_anticipation,
    target_investment_per_investment_global: state.profile.target_investment_per_investment,
    interested_investment_solution_global: state.profile.interested_investment_solution,
    other_assets_global: state.profile.other_assets,
    max_investment_horizon_global: state.profile.max_investment_horizon,
    risk_acceptance_global: state.profile.risk_acceptance,
    investment_experience_global: state.profile.investment_experience,
    liquid_worth_global: state.profile.liquid_worth,
    risk_factors_global: state.profile.risk_factors,
    qualified_purchaser_global: state.profile.qualified_purchaser
});

export default connect(mapStateToProps, { update_investor_profile })(InvestingProfile);
