import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const InputStep = ({ step, formData, setFormData }) => {
    const handleInputChange = (questionId, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: value,
        }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>{step.question.question}</Typography>
            <TextField
                key={step.question.id}
                label="Your Answer"
                variant="outlined"
                fullWidth
                value={formData[step.question.id] || ''}
                onChange={(e) => handleInputChange(step.question.id, e.target.value)}
                sx={{ width: '50%' }}
            />
        </Box>
    );
};

export default InputStep;
