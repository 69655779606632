import React from 'react';
import ChoiceStep from './ChoiceStep';
import InputStep from './InputStep';
import DateStep from './DateStep';
import MessageStep from './MessageStep';
import AcceptStep from './AcceptStep';
import CheckStep from './CheckStep';

const StepRenderer = ({ step, formData, setFormData, isAccepted, setIsAccepted }) => {
    switch (step.question.type) {
        case 'choice':
            return <ChoiceStep step={step} formData={formData} setFormData={setFormData} />;
        case 'input':
            return <InputStep step={step} formData={formData} setFormData={setFormData} />;
        case 'date':
            return <DateStep step={step} formData={formData} setFormData={setFormData} />;
        case 'message':
            return <MessageStep step={step} />;
        case 'accept':
            return <AcceptStep step={step} isAccepted={isAccepted} setIsAccepted={setIsAccepted} />;
        case 'check': 
            return <CheckStep step={step} formData={formData} setFormData={setFormData} />;
    
        default:
            return null;
    }
};

export default StepRenderer;
