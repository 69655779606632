import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

import CompanyDetails from './pages/CompanyDetails';
import Home from './pages/Home';
import Investments from './pages/Investments';
import Profile from './hocs/Profile';
import Order from './hocs/Order';
import Portfolio from './pages/Portfolio';
import HowItWorks from './pages/HowItWorks';
import RuleBook from './pages/RuleBook';
import Visualiser from './pages/ThemeVisualiser';
import Register from './pages/Register';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Screen from './pages/Screen';
import ErrorPage from './pages/ErrorPage'; 
import Layout from './hocs/Layout';
import { Provider } from 'react-redux';
import store from './store';
import WaitingListFrom from './components/WaitingListDetails';

function App() {



  return (
    <Provider store={store}>

      <ThemeProvider theme={theme}>

        <Router>

          <Layout>
            <Routes>

              <Route path="/" element={<Home />} />
              <Route path="/visualiser" element={<Visualiser />} />
              <Route path="/investments" element={<Investments />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/howitworks" element={<HowItWorks />} />
              <Route path="/company/:id" element={<CompanyDetails />} />
              <Route path="/deals/:dealId" element={<Order />} />
              <Route path="/waiting/:dealId" element= {<WaitingListFrom/>} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/documents/rulebooks/:title" element={< RuleBook />} />
              <Route path='/login' element={<Login />} />
              <Route path='/logout' element={<Logout />} />
              <Route path='/register' element={<Register />} />
              <Route path='/screening' element={<Screen />} />
              <Route path="/error" element={<ErrorPage errorCode={404} />} />
              
               {/* Catch-all route for undefined paths */}
               <Route path="*" element={<ErrorPage errorCode={404} />} />
            </Routes>
          </Layout>
        </Router>

      </ThemeProvider>

    </Provider>
  )
}
export default App;

