import React from 'react';
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import RuleBook from '../../pages/RuleBook';

const AcceptStep = ({ step, isAccepted, setIsAccepted }) => {
    const handleCheckboxChange = (stepId, checked) => {
        setIsAccepted((prevAccepted) => ({
            ...prevAccepted,
            [stepId]: checked,
        }));
    };
    console.log(step)
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>{step.question.question}</Typography>
            <RuleBook title={step.question.path}/>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isAccepted[step.id] || false}
                        onChange={(e) => handleCheckboxChange(step.id, e.target.checked)}
                    />
                }
                label="I Accept the Terms"
            />
        </Box>
    );
};

export default AcceptStep;
