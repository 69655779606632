import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const CheckStep = ({ step, formData, setFormData }) => {
    const theme = useTheme();

    const handleSelectChange = (questionId, choiceId) => {
        setFormData((prevData) => {
            const selectedChoices = prevData[questionId] || [];
            if (selectedChoices.includes(choiceId)) {
                // Remove if already selected
                return {
                    ...prevData,
                    [questionId]: selectedChoices.filter((id) => id !== choiceId),
                };
            } else {
                // Add new choice
                return {
                    ...prevData,
                    [questionId]: [...selectedChoices, choiceId],
                };
            }
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>{step.question.question}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                {step.question.choices.map((choice) => (
                    <Card
                        key={choice.id}
                        onClick={() => handleSelectChange(step.question.id, choice.id)}
                        sx={{
                            backgroundColor: (formData[step.question.id] || []).includes(choice.id) ? theme.palette.secondary.light : 'white',
                            width: '20%',
                        }}
                    >
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="body1">{choice.name}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default CheckStep;
