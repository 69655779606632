import React from 'react';
import {
  Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, Paper, FormControlLabel, Checkbox
} from '@mui/material';

const formatAsCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

const OrderReviewSection = ({ formData, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px', justifyContent: 'center' }}>
      {/* Section Heading */}
      <Typography variant="h6" sx={{ padding: '20px', textAlign: 'center' }}>
        Please review your order and click the button below to proceed to payment
      </Typography>

      {/* Order Review Table */}
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Reservation Amount</TableCell>
              <TableCell>{formatAsCurrency(formData.reservation_amount)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Share Price (excl. Sales Fee)</TableCell>
              <TableCell>{formatAsCurrency(formData.share_price)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Final Reservation Amount (excl. Sales Fee)</TableCell>
              <TableCell>{formatAsCurrency(formData.final_reservation_amount)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Equivalent Number of Shares</TableCell>
              <TableCell>{formData.equivalent_number_of_shares}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Agreement Checkboxes */}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.term_sheet_acceptance1}
              onChange={handleChange}
              name="term_sheet_acceptance1"
            />
          }
          label="I have read and accept the Terms and Conditions of EquitiBridge"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.term_sheet_acceptance2}
              onChange={handleChange}
              name="term_sheet_acceptance2"
            />
          }
          label="I understand that by proceeding I opt out of regulation"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.term_sheet_acceptance3}
              onChange={handleChange}
              name="term_sheet_acceptance3"
            />
          }
          label="I consent to anything else"
        />
      </Box>
    </Box>
  );
};

export default OrderReviewSection;
