import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Grid, Alert, Tab, Paper, Typography } from '@mui/material';
import ReservationDetails from '../components/order/ReservationDetails';
import AcceptTerms from '../components/order/AcceptTerms';
import CheckoutForm from '../components/order/CheckoutForm';
import ReviewOrder from '../components/order/ReviewOrder';
import api from '../axiosInstance';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import BankingProfiles from '../components/profiles/BankingProfiles';

const BlobContent = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '80%',
  margin: '0 auto',
  paddingTop: '40px',
  paddingBottom: '40px',
});

const OrderForm = () => {
  const [tabValue, setTabValue] = useState('1');
  const [formData, setFormData] = useState({
    reservation_amount: 0,
    share_price: 0,
    equivalent_number_of_shares: 0,
    final_reservation_amount: 0,
    term_sheet_acceptance1: false,
    term_sheet_acceptance2: false,
    term_sheet_acceptance3: false,
  });
  const [error, setError] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [orderMessage, setOrderMessage] = useState(''); // To store the backend response message
  const totalTabs = 5;
  const { dealId } = useParams();

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData, [name]: value };

    if (name === 'equivalent_number_of_shares') {
      newFormData = {
        ...newFormData,
        reservation_amount: Math.round(formData.share_price * value),
        final_reservation_amount: Math.round(formData.share_price * value) * 0.95,
      };
    }

    setFormData(newFormData);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNext = () => {
    const nextTab = parseInt(tabValue) + 1;
    setTabValue(nextTab.toString());
  };

  const handleBack = () => {
    const prevTab = parseInt(tabValue) - 1;
    setTabValue(prevTab.toString());
  };

  const createOrder = async () => {
    const orderData = {
      deal: dealId,
      reservation_amount: formData.reservation_amount,
      equivalent_number_of_shares: formData.equivalent_number_of_shares,
      final_reservation_amount: formData.final_reservation_amount,
      status: 0, // Status will be updated by backend
    };

    try {
      const orderResponse = await api.post('/api/orders/place_order/', orderData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      });

      if (orderResponse.status === 201) {
        const { message, partial_order, waiting_list_order } = orderResponse.data;

        if (partial_order && waiting_list_order) {
          // Handle partial and waiting list order
          setOrderMessage(
            `${message} Your reserved shares: ${partial_order.equivalent_number_of_shares}, ` +
            `waiting list shares: ${waiting_list_order.equivalent_number_of_shares}.`
          );
        } else {
          // Handle full reserved order
          setOrderMessage(orderResponse.data.message);
        }

        setOrderId(orderResponse.data.order ? orderResponse.data.order.id : null);
        const nextTab = parseInt(tabValue) + 1;
        setTabValue(nextTab.toString());
      } else {
        setError('Something went wrong, cannot proceed.');
      }
    } catch (err) {
      setError('An error occurred while placing the order.');
    }
  };

  const renderButtons = () => {
    if (tabValue === '1') {
      return (
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      );
    } else if (tabValue === (totalTabs - 1).toString()) {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="secondary" onClick={createOrder}>
            Proceed to Payment
          </Button>
        </>
      );
    } else if (tabValue === totalTabs.toString()) {
      return <></>;
    } else {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </>
      );
    }
  };

  return (
    <BlobContent>
      <Box component={Paper} style={{ width: '100%', padding: '60px', marginTop: '60px' }}>
        <form>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="order form tabs">
                <Tab label="Reservation Details" value="1" />
                <Tab label="Personal Information" value="2" />
                <Tab label="Accept Terms" value="3" />
                <Tab label="Review" value="4" />
                <Tab label="Payment" value="5" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <ReservationDetails formData={formData} setFormData={setFormData} handleChange={handleChange} />
            </TabPanel>

            <TabPanel value="2">
              <Alert severity="info">
                Please select a relevant payment profile for this transaction or add a new one. Note: P.O. Boxes are not accepted.
              </Alert>
              <BankingProfiles formData={formData} handleChange={handleChange} />
            </TabPanel>

            <TabPanel value="3">
              <AcceptTerms formData={formData} handleChange={handleChange} />
            </TabPanel>

            <TabPanel value="4">
              <ReviewOrder formData={formData} />
            </TabPanel>

            <TabPanel value="5">
              <CheckoutForm formData={formData} dealId={dealId} orderId={orderId} />
            </TabPanel>
          </TabContext>
        </form>

        <Grid
          container
          spacing={2}
          sx={{
            marginTop: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {renderButtons()}
          {orderMessage && (
            <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
              {orderMessage}
            </Typography>
          )}
          {error && (
            <Typography variant="body1" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Grid>
      </Box>
    </BlobContent>
  );
};

export default OrderForm;
