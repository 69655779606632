import React, { useState } from 'react';
import { FormControl, InputLabel, OutlinedInput, Grid, Button, Typography } from '@mui/material';
import CSRFToken from '../../components/general/CSRFToken';

const BasicProfile = () => {
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',
        confirm_new_password: ''
    });

    const { current_password, new_password, confirm_new_password } = formData;

    const onChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const onSubmit = e => {
        e.preventDefault();
        // Placeholder for the password update logic
        console.log('Password update form submitted:', formData);
    };

    return (
        <form onSubmit={e => onSubmit(e)}>
            <CSRFToken />
            <Typography variant="h6" gutterBottom>
                Basic Data
            </Typography>
            <Typography variant="body1" gutterBottom>
                Update Password
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='current_password'>Current Password</InputLabel>
                        <OutlinedInput
                            id='current_password'
                            type='password'
                            label='Current Password'
                            name='current_password'
                            value={current_password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='new_password'>New Password</InputLabel>
                        <OutlinedInput
                            id='new_password'
                            type='password'
                            label='New Password'
                            name='new_password'
                            value={new_password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant='outlined' fullWidth margin='normal'>
                        <InputLabel htmlFor='confirm_new_password'>Confirm New Password</InputLabel>
                        <OutlinedInput
                            id='confirm_new_password'
                            type='password'
                            label='Confirm New Password'
                            name='confirm_new_password'
                            value={confirm_new_password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" color='secondary' type="submit">
                Update Password
            </Button>
        </form>
    );
};

export default BasicProfile;
