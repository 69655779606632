import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../axiosInstance';
import { Button, Grid } from '@mui/material';
import CompanySummary from '../components/CompanySummary';
import LiveDeals from '../components/LiveDeals';
import Financials from '../components/Financials';
import MarketplaceBanner from '../components/general/MarketplaceBanner';
import Loading from '../components/general/Loading';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Press from '../components/Press';

const CompanyDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [deals, setDeals] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [companyResponse, dealsResponse] = await Promise.all([
                    api.get(`/api/products/companies/${id}/`),
                    api.get(`/api/products/deals/${id}/list/`)
                ]);
                setCompany(companyResponse.data);
                setDeals(dealsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) return
    <Grid>
        <MarketplaceBanner />
        <Grid container spacing={2} justifyContent="center" padding="40px">

            <Loading />;
        </Grid>

    </Grid>

console.log(company);

    return (
        <Grid>
            <MarketplaceBanner />
            <Grid container spacing={2} justifyContent="center" padding="40px">
                <Grid item xs={12} lg={10}>
                    <Button variant="text" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)}>
                        Back to companies list
                    </Button>
                </Grid>
                <Grid item xs={12} lg={10}>
                    <CompanySummary company={company} />
                </Grid>
                <Grid item xs={12} lg={10}>
                    <LiveDeals deals={deals} company={company}/>
                </Grid>
                <Grid item xs={12} lg={10}>
                    <Financials company={company} />
                </Grid>
                <Grid item xs={12} lg={10}>
                    <Press company={company} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CompanyDetails;
