import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Box, Grid, Paper, TableContainer, Tab, Dialog,
    DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import api from '../axiosInstance';
import CheckoutForm from '../components/order/CheckoutForm';
import NumberFormatter from '../components/helpers/NumberFormatter';
import SearchBar from '../components/general/SearchBar';

const columns = [
    {
        field: 'logo',
        headerName: '',
        renderCell: (params) => <img alt='logo' src={params.value} style={{ maxHeight: '50px', maxWidth: '100%', borderRadius: 5, padding: '10px' }} />,
        sortable: false,
        filterable: false,
        width: 60,
    },
    { field: 'name', headerName: 'Company', width: 130 },
    {
        field: 'final_reservation_amount',
        headerName: 'Reservation Amount',
        renderCell: (params) => <NumberFormatter number={params.value} />,
        width: 200,
    },
    { field: 'equivalent_number_of_shares', headerName: '# of shares', width: 180 },
    {
        field: 'implied_share_price',
        headerName: 'Share price',
        renderCell: (params) => <NumberFormatter number={params.value} />,
        width: 180,
    },
];

const Portfolio = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentTab, setCurrentTab] = useState('1');
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [dealId, setDealId] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState({
        Watchlist: [], Reserved: [], Waiting: [], Paid: [], Closed: [], Exited: []
    });

    const unpaidColumns = [
        ...columns,
        {
            field: 'button',
            headerName: '',
            renderCell: (params) => (
                <Button variant="contained" color="secondary" onClick={() => handleClickOpen(params.row)}>
                    Make Payment
                </Button>
            ),
            width: 180,
        }
    ];

    const handleClickOpen = (rowData) => {
        const quantity = rowData.equivalent_number_of_shares || 0;
        setOrderId(rowData.id);
        setOrderData({ price: "price_1Ps3UQCFu7uROflV4KmutHzj", quantity });
        setDealId(rowData.deal);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDealId(null);
        setOrderData(null);
    };

    const fetchOrders = async () => {
        try {
            const { data: ordersData } = await api.get('api/orders/list/me/');
            setFilteredOrders({
                Watchlist: ordersData.filter(order => order.status === 0),
                Reserved: ordersData.filter(order => order.status === 1),
                Waiting: ordersData.filter(order => order.status === 2),
                Paid: ordersData.filter(order => order.status === 4),
                Closed: ordersData.filter(order => order.status === 5),
                Exited: ordersData.filter(order => order.status === 6),
            });
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const getRows = (orders) => {
        return orders.map(order => ({
            ...order,
            button: 'Please Pay'
        }));
    };

    return (
        <div>
            <Grid container display="flex" spacing={2} justifyContent={'center'} padding="40px">
                <Grid item xs={12} lg={10} component={Paper} sx={{ width: '100%', typography: 'body1', padding: '60px' }}>
                    <Typography variant="h3" gutterBottom>Portfolio</Typography>
                    <Box width="100%" sx={{ display: 'flex' }}>
                        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Box>
                    <TabContext value={currentTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="portfolio navigation" textColor="secondary" indicatorColor="secondary">
                                {['Watchlist', 'Reserved', 'Waiting', 'Paid', 'Closed', 'Exited'].map((label, index) => (
                                    <Tab key={index} label={label} value={(index + 1).toString()} />
                                ))}
                            </TabList>
                        </Box>
                        {['Watchlist', 'Reserved', 'Waiting', 'Paid', 'Closed', 'Exited'].map((status, index) => (
                            <TabPanel key={index} value={(index + 1).toString()}>
                                <Typography variant="h4" gutterBottom>{`${status}`}</Typography>
                                <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 400 }}>
                                    <DataGrid
                                        rows={getRows(filteredOrders[status])}
                                        columns={status === 'Reserved' ? unpaidColumns : columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        disableColumnMenu
                                    />
                                </TableContainer>
                            </TabPanel>
                        ))}
                    </TabContext>
                    <Button variant="contained" color="secondary" href="/investments"> Explore new offerings </Button>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Complete Payment</DialogTitle>
                <DialogContent>
                    {orderData && dealId && orderId && (
                        <CheckoutForm formData={orderData} dealId={dealId} orderId={orderId} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Portfolio;
